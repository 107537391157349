<template>
  <div class="study_main_calendar_titlebox">{{ selectedMonth }}</div>
</template>

<script>
export default {
  props: {
    selectedDate: {
      type: Object,
      required: true
    }
  },

  computed: {
    selectedMonth () {
      return this.selectedDate.format('MMMM YYYY')
    }
  }
}
</script>

<style scoped>
</style>
