<template>
  <td
    :title="bookTitle"
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar_display_on01 clickable' : (events !== null && day.isCurrentMonth ? true : false),
      'calendar-item-scheduled' : (events !== null && events[0].status_class === 'scheduled'),
      'calendar-item-attend' : (events !== null && events[0].status_class === 'attend'),
      'calendar-item-absent' : (events !== null && events[0].status_class === 'absent'),
      'calendar-item-teacher-cancel' : (events !== null && events[0].status_class === 'teacher-cancel'),
      'calendar-item-postponed' : (events !== null && events[0].status_class === 'postponed')
    }"
    @click="getClassSchedule(events)"
  >
    <span v-if="day.isCurrentMonth">{{ label }}</span>
    <span v-else>&nbsp;</span>
  </td>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CalendarMonthDayItem',

  props: {
    day: {
      type: Object,
      required: true
    },
    events: {
      type: Array,
      required: false
    },

    isCurrentMonth: {
      type: Boolean,
      default: false
    },

    isToday: {
      type: Boolean,
      default: false
    },
    demo: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    label () {
      return dayjs(this.day.date).format('D')
    },
    bookTitle () {
      let bookTitle = ''
      if (this.events !== null) {
        bookTitle = this.events[0].title
      }
      return bookTitle
    }
  },
  methods: {
    getClassSchedule (events) {
      if (events !== null) {
        if (this.demo) {
          this.$router.replace({ name: 'demo.study-room.content', params: { talkScheduleId: events[0].id } })
        } else {
          const route = this.$router.resolve({ name: 'study-room.content', params: { talkScheduleId: events[0].id } })
          window.open(route.href, '_blank')
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
