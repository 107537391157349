<template>

  <div class="study_main_calendar_title">
    <p class="study_main_calendar_btn01" @click="selectPrevious">
      <a href="#"><img src="/image/sub/study_main_calendar_arr_left.png"  alt="이전 버튼" /></a>
    </p>
    <div class="study_main_calendar_title_h4">
      <h4>시리즈 학습일정</h4>
    </div>
    <p class="study_main_calendar_btn02">
      <a href="#" @click="selectNext">
        <img src="/image/sub/study_main_calendar_arr_right.png" alt="다음 버튼" />
      </a>
    </p>
  </div>

</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'CalendarModeSelector',

  props: {
    currentDate: {
      type: String,
      required: true
    },

    selectedDate: {
      type: Object,
      required: true
    }
  },

  methods: {
    selectPrevious () {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month')
      this.$emit('dateSelected', newSelectedDate)
    },

    selectCurrent () {
      const newSelectedDate = dayjs(this.currentDate)
      this.$emit('dateSelected', newSelectedDate)
    },

    selectNext () {
      const newSelectedDate = dayjs(this.selectedDate).add(1, 'month')
      this.$emit('dateSelected', newSelectedDate)
    }
  }
}
</script>

<style scoped>
.study_main_calendar_btn01, .study_main_calendar_btn02 {
  z-index: 1;
}
</style>
