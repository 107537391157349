<template>
  <thead>
    <tr>
      <th v-for="weekday in weekdays" :key="weekday">{{ weekday }}</th>
    </tr>
  </thead>
</template>

<script>
const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

export default {
  name: 'CalendarWeekdays',

  computed: {
    weekdays () {
      return WEEKDAYS
    }
  }
}
</script>

<style scoped>
</style>
