var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"calendar-day",class:{
    'calendar-day--not-current': !_vm.day.isCurrentMonth,
    'calendar-day--today': _vm.isToday,
    'calendar_display_on01 clickable' : (_vm.events !== null && _vm.day.isCurrentMonth ? true : false),
    'calendar-item-scheduled' : (_vm.events !== null && _vm.events[0].status_class === 'scheduled'),
    'calendar-item-attend' : (_vm.events !== null && _vm.events[0].status_class === 'attend'),
    'calendar-item-absent' : (_vm.events !== null && _vm.events[0].status_class === 'absent'),
    'calendar-item-teacher-cancel' : (_vm.events !== null && _vm.events[0].status_class === 'teacher-cancel'),
    'calendar-item-postponed' : (_vm.events !== null && _vm.events[0].status_class === 'postponed')
  },attrs:{"title":_vm.bookTitle},on:{"click":function($event){return _vm.getClassSchedule(_vm.events)}}},[(_vm.day.isCurrentMonth)?_c('span',[_vm._v(_vm._s(_vm.label))]):_c('span')])}
var staticRenderFns = []

export { render, staticRenderFns }