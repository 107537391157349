<template>
  <div class="study_main_calendar_area">
    <CalendarDateSelector
      :current-date="today"
      :selected-date="selectedDate"
      @dateSelected="selectDate"
    />
    <div class="study_main_calendar_month">
      <CalendarDateIndicator
        :selected-date="selectedDate"
        class="calendar-month-header-selected-month"
      />
      <div class="study_main_calendar_table">
        <table class="board_typeC" summary="달력 리스트">
          <CalendarWeekdays/>
          <tbody>
            <tr v-for="(week, weekIndex) in weeks" :key="weekIndex">
              <CalendarMonthDayItem
                v-for="day in week"
                :key="day.date"
                :day="day"
                :is-today="day.date === today"
                :events="(typeof events[day.date] !== 'undefined' ? events[day.date] : null)"
                :demo="demo"
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="calendar-legends">
      <div class="calendar-legend-item" :class="{'disable' : !legends.includes('Scheduled') }" @click="updateLegend('Scheduled')"><span class="calendar-legend-box scheduled"></span> Scheduled</div>
      <div class="calendar-legend-item" :class="{'disable' : !legends.includes('Attend') }" @click="updateLegend('Attend')"><span class="calendar-legend-box attend"></span>Attend</div>
      <div class="calendar-legend-item" :class="{'disable' : !legends.includes('Absent') }" @click="updateLegend('Absent')"><span class="calendar-legend-box absent"></span>Absent</div>
      <div class="calendar-legend-item" :class="{'disable' : !legends.includes('Teacher Cancel') }" @click="updateLegend('Teacher Cancel')"><span class="calendar-legend-box teacher-cancel"></span>Teacher Cancel</div>
      <div class="calendar-legend-item" :class="{'disable' : !legends.includes('Postponed') }" @click="updateLegend('Postponed')"><span class="calendar-legend-box postponed"></span>Postponed</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import CalendarDateIndicator from '@/components/Calendar/CalendarDateIndicator'
import CalendarDateSelector from '@/components/Calendar/CalendarDateSelector'
import CalendarWeekdays from '@/components/Calendar/CalendarWeekdays'
import CalendarMonthDayItem from '@/components/Calendar/CalendarMonthDayItem'

dayjs.extend(weekday)
dayjs.extend(weekOfYear)

export default {
  name: 'CalendarMonth',

  props: {
    events: {
      type: [Array, Object],
      required: false
    },
    legends: {
      type: [Array],
      required: false
    },
    demo: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
    CalendarMonthDayItem
  },

  data () {
    return {
      selectedDate: dayjs()
    }
  },

  computed: {
    days () {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays
      ]
    },

    weeks () {
      var perChunk = 7 // items per chunk
      var inputArray = this.days
      var result = inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])

      return result
    },

    today () {
      return dayjs().format('YYYY-MM-DD')
    },

    month () {
      return Number(this.selectedDate.format('M'))
    },

    year () {
      return Number(this.selectedDate.format('YYYY'))
    },

    numberOfDaysInMonth () {
      return dayjs(this.selectedDate).daysInMonth()
    },

    currentMonthDays () {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            'YYYY-MM-DD'
          ),
          isCurrentMonth: true
        }
      })
    },

    previousMonthDays () {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      )
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        'month'
      )

      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
        .date()

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() +
                1}-${previousMonthLastMondayDayOfMonth + index}`
            ).format('YYYY-MM-DD'),
            isCurrentMonth: false
          }
        }
      )
    },

    nextMonthDays () {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      )

      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month')

      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday

      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format('YYYY-MM-DD'),
          isCurrentMonth: false
        }
      })
    }
  },

  methods: {
    getWeekday (date) {
      return dayjs(date).weekday()
    },

    selectDate (newSelectedDate) {
      this.selectedDate = newSelectedDate
    },

    updateLegend (data) {
      this.$emit('updateLegend', data)
    }
  }
}
</script>

<style scoped>
</style>
